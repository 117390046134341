//import Header from '../myf-cilent-header/header.js';
import React, { useState, useEffect } from 'react';
import API from '../myf-api/api.js';
import Loader from '../components/loader.js'

function Home() {
    const [installmentInformation, setInstallmentInformation] = useState([]);
    const [customerCount, setCustomerCount] = useState(0);
    const [totalAmountPaid, setTotalAmountPaid] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const url = API();
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}/api/customerCount`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setCustomerCount(data.customerCount);
            } catch (error) {
                console.error('Error fetching customer count:', error);
            }
        };

        fetchData();
        document.title = 'Home - Our Finance';
    }, [url, token]);

    const handleDateSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const date = event.target.elements.date.value;
        const response = await fetch(`${url}/api/dailyPaymentDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ date }),
        });

        const data = await response.json();
        setLoading(false);
        setInstallmentInformation(data.installmentInformation);
        setTotalAmountPaid(data.totalAmountPaid);
        setFormSubmitted(true);
    };

    return (
        <div>
            {/* <Header /> */}
            <h2>Welcome to Our Finance portal</h2>
            <p>Number of Customers: {customerCount}</p>
            <form onSubmit={handleDateSubmit}>
                <h4>Select date for daily installment details</h4>
                <input type="date" id="date" name="date" required />
                <button type="submit">Submit</button>
            </form>

            {loading ? <Loader /> :
                formSubmitted && (installmentInformation.length > 0 ? (
                    <div>
                        <h2>Daily Installment Payment</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>File Number</th>
                                    <th>Customer Name</th>
                                    <th>Amount Paid</th>
                                    <th>Installment Number</th>
                                    <th>Receipt Number</th>
                                    <th>Mode Of Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {installmentInformation.map((item,index) => (
                                    item.amountPaid > 0 && (
                                        <tr key={item.customerName}>
                                            <td>{index + 1}</td>
                                            <td>{item.customerFileNumber}</td>
                                            <td>{item.customerName}</td>
                                            <td>Rs:{item.amountPaid}/-</td>
                                            <td>{item.paymentInstallment}</td>
                                            <td>{item.receiptNumber}</td>
                                            <td>{item.modeOfPayment}</td>
                                        </tr>
                                    )
                                ))}
                            </tbody>
                        </table>
                        <p>Total Amount - Rs:{totalAmountPaid}/-</p>
                    </div>
                ) :
                    (<><h2>Daily Installment Payment</h2><p>No Data Found</p></>))
            }
        </div>
    );
}

export default Home;
