import React, { useState } from 'react';
import './header.css';
import menuIcon from '../components/menu.png';

const Header = ({ userRole }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <header>
      <nav>
        <ul>
          {(userRole === 'Owner' || userRole === 'Accounts' || userRole === 'Line Manager') && <li><a href="/home">Our Finance</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts') && <li><a href="/addCustomer">Add Customer Details</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts' || userRole === 'Line Manager') && <li><a href="/myCustomers">My Customers</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts' || userRole === 'Line Manager') && <li><a href="/pendingList">Pending List</a></li>}
          {(userRole === 'Owner' ) &&<li><a href="/addPaymentDetails">Add Payment Details</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts' ) && <li><a href="/customerDetails">Customer Installment Details</a></li>}
          {(userRole === 'Owner' ) &&<li><a href="/ledger">Ledger</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts' || userRole === 'Line Manager') && <li><a href="/vehicleManagement">Vehicle Management</a></li>}
          {(userRole === 'Owner' || userRole === 'Accounts' ) && <li className="dropdown">
            <img
              src={menuIcon}
              alt="Menu"
              className="menu-icon"
              onClick={toggleDropdown}
            />
            {showDropdown && (
              <div className="dropdown-content">
                <a href="/financeBFCalculator">BF Calculator</a>
              </div>
            )}
          </li>}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
