import React from "react";
import "./MobileFilterPopup.css";

const MobileFilterPopup = ({
  nameFilter,
  setNameFilter,
  selectedLineBusinessFilter,
  setSelectedLineBusinessFilter,
  lineBusinessFilters,
  selectedAgentFilter,
  setSelectedAgentFilter,
  agentNames,
  pendingFilter,
  setPendingFilter,
  pendingValue,
  setPendingValue,
  selectedAgreementStatus,
  setSelectedAgreementStatus,
  onClose,
}) => {
  return (
    <div className="mobile-filter-popup-overlay">
      <div className="mobile-filter-popup">
        <div className="popup-header">
          <h3>Filters</h3>
          <button className="close-btn" onClick={onClose}>✖</button>
        </div>
        
        <div className="popup-content">
          <label>Filter by Name:</label>
          <input type="text" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />

          <label>Filter by Line:</label>
          <select value={selectedLineBusinessFilter} onChange={(e) => setSelectedLineBusinessFilter(e.target.value)}>
            <option value="">All</option>
            {lineBusinessFilters.map((line) => (
              <option key={line} value={line}>
                {line}
              </option>
            ))}
          </select>

          <label>Filter by Agent:</label>
          <select value={selectedAgentFilter} onChange={(e) => setSelectedAgentFilter(e.target.value)}>
            <option value="">All</option>
            {agentNames.map((agent) => (
              <option key={agent} value={agent}>
                {agent}
              </option>
            ))}
          </select>

          <label>Pending Installments:</label>
          <div className="pending-filter-group">
            <select value={pendingFilter} onChange={(e) => setPendingFilter(e.target.value)}>
              <option value="">Select</option>
              <option value="less than">Less than</option>
              <option value="equal to">Equal to</option>
              <option value="greater than">Greater than</option>
            </select>
            <input type="number" value={pendingValue} onChange={(e) => setPendingValue(e.target.value)} />
          </div>

          <label>Agreement Status:</label>
          <select value={selectedAgreementStatus} onChange={(e) => setSelectedAgreementStatus(e.target.value)}>
            <option value="">Select</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>

          <button className="apply-filters" onClick={onClose}>Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterPopup;
