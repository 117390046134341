import React, { useState } from 'react';
import API from '../myf-api/api.js';

function AddCustomer() {
    const [formData, setFormData] = useState({
        fileNumber: '',
        firstname: '',
        fathername: '',
        date: '',
        bikeType: '',
        bikeModel: '',
        amount: '',
        emiAmount: '',
        installments: '',
        nominee: '',
        guarantorfathername: '',
        aadhar: '',
        address: '',
        lineOfBussiness: '',
        phoneNumber: '',
        handLoan: '',
        guarantorAddress: '',
        guarantorAddressPhoneNumber: '',
        agentName: '',
    });

    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (accordion) => {
        setActiveAccordion((prev) => (prev === accordion ? null : accordion));
    };

    const url = API();
    const token = localStorage.getItem('accessToken');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'fileNumber'? value.trim() : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${url}/api/addCustomerDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 200) {
            window.alert(`Customer added successfully! File Number: ${formData.fileNumber}`);
            setFormData({
                fileNumber: '',
                firstname: '',
                fathername: '',
                date: '',
                bikeType: '',
                bikeModel: '',
                amount: '',
                emiAmount: '',
                installments: '',
                nominee: '',
                guarantorfathername: '',
                aadhar: '',
                address: '',
                lineOfBussiness: '',
                phoneNumber: '',
                handLoan: '',
                guarantorAddress: '',
                guarantorAddressPhoneNumber: '',
                agentName: '',
            });
        } else {
            const data = await response.json();
            window.alert(`Error: ${data.message}`);
        }
    };

    document.title = 'Add Customer - Our Finance';

    return (
        <div>
            <h2>Add Customer Details</h2>
            <form id="customer-form" onSubmit={handleSubmit}>
                <div className="accordion">
                    <div className="accordion-item">
                        <h3 onClick={() => toggleAccordion('personal')} style={{ cursor: 'pointer' }}>
                            Customer Information {activeAccordion === 'personal' ? '▲' : '▼'}
                        </h3>
                        {activeAccordion === 'personal' && (
                            <div className="accordion-content">
                                <div className="form-group">
                                    <label htmlFor="fileNumber">File Number:</label>
                                    <input type="text" id="fileNumber" name="fileNumber" value={formData.fileNumber} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="firstname">Customer Name:</label>
                                    <input type="text" id="firstname" name="firstname" value={formData.firstname} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fathername">Father Name:</label>
                                    <input type="text" id="fathername" name="fathername" value={formData.fathername} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Customer Address:</label>
                                    <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Customer Phone Number:</label>
                                    <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="aadhar">Customer Aadhar Number:</label>
                                    <input type="text" id="aadhar" name="aadhar" value={formData.aadhar} onChange={handleChange} required />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="accordion-item">
                        <h3 onClick={() => toggleAccordion('finance')} style={{ cursor: 'pointer' }}>
                            Finance Details {activeAccordion === 'finance' ? '▲' : '▼'}
                        </h3>
                        {activeAccordion === 'finance' && (
                            <div className="accordion-content">
                                <div className="form-group">
                                    <label htmlFor="date">Date of Finance:</label>
                                    <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
                                </div>
                                <div>
                                    <label htmlFor="bikeType">Bike Type:</label>
                                    <select name="bikeType" value={formData.bikeType} onChange={handleChange} required>
                                        <option value="">Select an option</option>
                                        <option value="new">New</option>
                                        <option value="old">Old</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bikeModel">Bike Model:</label>
                                    <input type="text" id="bikeModel" name="bikeModel" value={formData.bikeModel} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="handLoan">Hand Loan (HL):</label>
                                    <input type="number" id="handLoan" name="handLoan" value={formData.handLoan} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="amount">Finance Amount:</label>
                                    <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emiAmount">EMI Amount:</label>
                                    <input type="number" id="emiAmount" name="emiAmount" value={formData.emiAmount} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="installments">No of Installments:</label>
                                    <input type="number" id="installments" name="installments" value={formData.installments} onChange={handleChange} required />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="accordion-item">
                        <h3 onClick={() => toggleAccordion('guarantor')} style={{ cursor: 'pointer' }}>
                            Guarantor Details {activeAccordion === 'guarantor' ? '▲' : '▼'}
                        </h3>
                        {activeAccordion === 'guarantor' && (
                            <div className="accordion-content">
                                <div className="form-group">
                                    <label htmlFor="nominee">Guarantor Name:</label>
                                    <input type="text" id="nominee" name="nominee" value={formData.nominee} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="guarantorfathername">Guarantor Father Name:</label>
                                    <input type="text" id="guarantorfathername" name="guarantorfathername" value={formData.guarantorfathername} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="guarantorAddress">Guarantor Address:</label>
                                    <input type="text" id="guarantorAddress" name="guarantorAddress" value={formData.guarantorAddress} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="guarantorAddressPhoneNumber">Guarantor Phone Number:</label>
                                    <input type="text" id="guarantorAddressPhoneNumber" name="guarantorAddressPhoneNumber" value={formData.guarantorAddressPhoneNumber} onChange={handleChange} required />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="accordion-item">
                        <h3 onClick={() => toggleAccordion('business')} style={{ cursor: 'pointer' }}>
                            Business Details {activeAccordion === 'business' ? '▲' : '▼'}
                        </h3>
                        {activeAccordion === 'business' && (
                            <div className="accordion-content">
                                <div className="form-group">
                                    <label htmlFor="lineOfBussiness">Line of Business:</label>
                                    <input type="text" id="lineOfBussiness" name="lineOfBussiness" value={formData.lineOfBussiness} onChange={handleChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="agentName">File Agent:</label>
                                    <input type="text" id="agentName" name="agentName" value={formData.agentName} onChange={handleChange} required />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <button type="submit" name="addCustomer">Add Customer</button>
            </form>
        </div>
    );
}

export default AddCustomer;
