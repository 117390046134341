import React, { useState, useEffect, useLayoutEffect } from 'react';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import 'jspdf-autotable';
import API from '../myf-api/api.js';
import './CustomerPendingList.css';
import CustomerPopup from './customerDetailsPopUp.js';
import Loader from '../components/loader.js';
import MobileFilterPopup from './customerPendningListPopUp.js';

const CustomerPendingList = () => {
  const [date, setdate] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [lineBusinessFilters, setLineBusinessFilters] = useState([]);
  const [selectedLineBusinessFilter, setSelectedLineBusinessFilter] = useState('');
  const [pendingFilter, setPendingFilter] = useState('');
  const [pendingValue, setPendingValue] = useState('');
  const [agentNames, setAgentNames] = useState([]);
  const [selectedAgentFilter, setSelectedAgentFilter] = useState('');
  const [selectedAgreementStatus, setSelectedAgreementStatus] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  const tableRef = React.createRef();
  const url = API();
  const token = localStorage.getItem('accessToken');

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${url}/customerPendingList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ date }),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        setCustomers(data.customers);

        const uniqueAddresses = [...new Set(data.customers.map(customer => customer.lineOfBussiness))];
        setLineBusinessFilters(uniqueAddresses);

        const uniqueAgentNames = [...new Set(data.customers.map(customer => customer.agentName))];
        setAgentNames(uniqueAgentNames);

      } else {
        console.error('Error fetching customer data');
      }
    } catch (error) {
      console.error('Error fetching customer data', error);
    }
  };

  useEffect(() => {
    let filteredData = customers;

    if (nameFilter) {
      filteredData = filteredData.filter(customer =>
        customer.firstname.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (selectedLineBusinessFilter) {
      filteredData = filteredData.filter(customer =>
        customer.lineOfBussiness === selectedLineBusinessFilter
      );
    }

    if (selectedAgentFilter) {
      filteredData = filteredData.filter(customer =>
        customer.agentName === selectedAgentFilter
      );
    }

    if (selectedAgreementStatus) {
      filteredData = filteredData.filter(customer =>
        customer.isAgreementInactive === selectedAgreementStatus
      );
    }

    if (pendingFilter && pendingValue !== '') {
      filteredData = filteredData.filter(customer => {
        const pendingInt = parseInt(customer.pendingInstallments, 10);
        const valueInt = parseInt(pendingValue, 10);

        switch (pendingFilter) {
          case 'less than':
            return pendingInt <= valueInt;
          case 'equal to':
            return pendingInt === valueInt;
          case 'greater than':
            return pendingInt >= valueInt;
          default:
            return true;
        }
      });
    }

    setFilteredCustomers(filteredData);

    const totalAmount = filteredData.reduce((sum, customer) => {
      const pendingAmount =
        customer.postPaymentbal > 0
          ? customer.pendingInstallments * customer.perInstallmentAmount + customer.postPaymentbal
          : customer.pendingInstallments * customer.perInstallmentAmount;
      return sum + pendingAmount;
    }, 0);
    setTotalPendingAmount(totalAmount);
  }, [customers, nameFilter, selectedLineBusinessFilter, selectedAgentFilter, pendingFilter, pendingValue, selectedAgreementStatus]);

  document.title = 'Pending List - Our Finance';

  return (
    <div>

      <h2>Customer Pending List</h2>
      <form onSubmit={handleSubmit}>
        <h4>Select Date to See the Pending list</h4>
        <input
          type="date"
          id="date"
          name="date"
          value={date}
          onChange={(e) => setdate(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
      <br></br>

      {isMobile ? (
        <button className="mobile-filter-btn" onClick={() => setIsFilterPopupOpen(true)}>
          Open Filters
        </button>
      ) : (


        <div className="filter-container">
          <div>
            <label>Filter by Name:</label>
            <input
              type="text"
              placeholder="Enter name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>

          <div>
            <label>Filter by Line:</label>
            <select
              value={selectedLineBusinessFilter}
              onChange={(e) => setSelectedLineBusinessFilter(e.target.value)}
            >
              <option value="">All</option>
              {lineBusinessFilters.map(lineOfBussiness => (
                <option key={lineOfBussiness} value={lineOfBussiness}>
                  {lineOfBussiness}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label>Filter by Agent Name:</label>
            <select
              value={selectedAgentFilter}
              onChange={(e) => setSelectedAgentFilter(e.target.value)}
            >
              <option value="">Select Agent</option>
              {agentNames.map(agentName => (
                <option key={agentName} value={agentName}>
                  {agentName}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label>Filter by Pending Installments:</label>
            <div className="pending-filter-inputs">
              <select
                value={pendingFilter}
                onChange={(e) => setPendingFilter(e.target.value)}
              >
                <option value="">Select Filter</option>
                <option value="less than">Less than</option>
                <option value="equal to">Equal to</option>
                <option value="greater than">Greater than</option>
              </select>
              <input
                type="number"
                placeholder="Enter value"
                value={pendingValue}
                onChange={(e) => setPendingValue(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label>Agreement Status:</label>
            <select
              value={selectedAgreementStatus}
              onChange={(e) => setSelectedAgreementStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>


        </div>
      )}
      {filteredCustomers.length > 0 && (
        <div>
          <button className="print-table-btn" onClick={handlePrint}>
            Print Pending List
          </button>
        </div>
      )}

      {selectedCustomer && (
        <CustomerPopup
          customer={selectedCustomer}
          onClose={() => setSelectedCustomer(null)}
        />
      )}

      {isFilterPopupOpen && (
        <MobileFilterPopup
          nameFilter={nameFilter} setNameFilter={setNameFilter}
          selectedLineBusinessFilter={selectedLineBusinessFilter} setSelectedLineBusinessFilter={setSelectedLineBusinessFilter}
          lineBusinessFilters={lineBusinessFilters}
          selectedAgentFilter={selectedAgentFilter} setSelectedAgentFilter={setSelectedAgentFilter}
          agentNames={agentNames}
          pendingFilter={pendingFilter} setPendingFilter={setPendingFilter}
          pendingValue={pendingValue} setPendingValue={setPendingValue}
          selectedAgreementStatus={selectedAgreementStatus} setSelectedAgreementStatus={setSelectedAgreementStatus}
          onClose={() => setIsFilterPopupOpen(false)}
        />
      )}

      {loading ? <Loader /> :

        filteredCustomers.length > 0 && (
          <div id="customerTable" ref={tableRef}>
            <p>Report Date: {format(new Date(), 'dd-MM-yyyy')}</p>
            <p>Pending List till: {format(new Date(date), 'dd-MM-yyyy')}</p>
            {nameFilter !== '' && (<p>Name: {nameFilter}</p>)}
            {selectedLineBusinessFilter !== '' && (
              <p>Line of Business: {selectedLineBusinessFilter}</p>
            )}
            {selectedAgentFilter !== '' && (
              <p>Agent Name: {selectedAgentFilter}</p>
            )}
            {pendingFilter && pendingValue !== '' && (
              <p>
                Pending Installments: {pendingFilter} {pendingValue}
              </p>
            )}
            {selectedAgreementStatus !== '' && (
              <p>Agreement Status: {selectedAgreementStatus}</p>
            )}
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>File Number</th>
                    <th>Agreement Date</th>
                    <th>Paid EMIs</th>
                    <th>Pending EMIs</th>
                    <th>EMI Amt</th>
                    <th>Amount Pending</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Bike Details</th>
                    <th>Agent</th>
                    <th>Agt Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCustomers.map((customer, index) => (
                    <tr key={customer.fileNumber}>
                      <td>{index + 1}</td>
                      <td>
                        {customer.firstname}{' '}
                        <button className="view-details-btn view-details-btn-hide-on-print" onClick={() => setSelectedCustomer(customer)}>
                          Details
                        </button>
                      </td>
                      <td>{customer.fileNumber}</td>
                      <td>{format(new Date(customer.takenDate), 'dd-MM-yyyy')}</td>
                      <td>{customer.paidInstallments}</td>
                      <td>
                        <div>{customer.pendingInstallments}</div>
                        <div>{(customer.pendingInstallments === 0 && customer.totalPenalties !== 0) ? "PT- " + customer.totalPenalties : ''}</div>
                        <div>{customer.postPaymentbal > 0 ? `Bal-Rs:${formatAmount(customer.postPaymentbal)}` : ''}</div>
                      </td>
                      <td>{formatAmount(customer.perInstallmentAmount)}</td>
                      <td>{formatAmount(
                        customer.postPaymentbal > 0
                          ? customer.pendingInstallments * customer.perInstallmentAmount + customer.postPaymentbal
                          : customer.pendingInstallments * customer.perInstallmentAmount
                      )}
                      </td>
                      <td>{customer.address}</td>
                      <td>
                        <div>{customer.phoneNumber}</div>
                        <div>--------------</div>
                        <div>{customer.guarantorAddressPhoneNumber}</div>
                      </td>
                      <td>{customer.bikeModel}</td>
                      <td>{customer.agentName}</td>
                      <td>{customer.isAgreementInactive}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="total-pending-amount">
              <h4>Total Pending Amount: {formatAmount(totalPendingAmount)}</h4>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CustomerPendingList;
